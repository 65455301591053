import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import * as SockJS from 'sockjs-client';
import { BACKEND_URL } from 'src/configurations/config';
import * as Stomp from 'stompjs';
import { AveragesResponse } from './models/averagesResponse';
import { ResponseJSON } from './models/responseJSON';

@Injectable()
export class ServerService {

  private url = BACKEND_URL;

  private ws;
  private connectedSuccessfully = false;
  private stompClient;
  private alreadyTryingConnect = false;
  private hearbeatTimeout;

  public onMessage = new EventEmitter<ResponseJSON>();
  public onError = new EventEmitter<void>();
  public onAverages = new EventEmitter<AveragesResponse>();
  public onMessageWithAverage = new EventEmitter<number>();
  public onStartConnection = new EventEmitter<void>();

  constructor(private http: HttpClient) {
    this.setWebsocket();
  }

  private getParkingInfo() {
    this.http.get(this.url + '/parkingInfo').subscribe(
      (data: ResponseJSON) => this.onMessage.emit(data),
      () => this.onError.emit()
    );
  }

  private getAverages() {
    this.http.get(this.url + '/actualChart').subscribe(
      (data: AveragesResponse) => this.onAverages.emit(data),
      () => this.onError.emit()
    );
  }

  private setWebsocket() {
    if (this.ws) {
      this.ws.close();
    }
    this.getParkingInfo();
    this.getAverages();
    this.ws = new SockJS(this.url + '/ws');
    this.stompClient = Stomp.over(this.ws);
    this.stompClient.connect({}, (frame) => {
      this.connectedSuccessfully = true;
      this.stompClient.subscribe('/parking',
        (message) => {
          const response: ResponseJSON = JSON.parse(message.body);
          this.onMessage.emit(response);
        }
      );
      this.stompClient.subscribe('/average',
        (message) => {
          const response: AveragesResponse = JSON.parse(message.body);
          this.onAverages.emit(response);
        }
      );
      this.stompClient.subscribe('/ping',
        (message) => {
          clearTimeout(this.hearbeatTimeout);
          this.hearbeatTimeout = setTimeout(
            () => {
              this.onError.emit();
              this.ws.onheartbeat = null;
              this.tryConnect();
            },
            40000
          );
        }
      );
    });
    this.ws.onclose = () => {
      this.onError.emit();
      this.tryConnect();
    };
    this.ws.onerror = () => {
      this.onError.emit();
      this.tryConnect();
    };
    clearTimeout(this.hearbeatTimeout);
    this.hearbeatTimeout = setTimeout(
      () => {
        this.onError.emit();
        this.tryConnect();
      },
      40000
    );
  }

  private tryConnect(): void {
    if (this.alreadyTryingConnect) {
      return;
    }
    this.alreadyTryingConnect = true;
    this.connectedSuccessfully = false;
    const interval = setInterval(
      () => {
        if (this.connectedSuccessfully) {
          this.alreadyTryingConnect = false;
          clearInterval(interval);
        } else {
          this.setWebsocket();
        }
      },
      5000
    );
  }

  onReconnect() {
    this.getParkingInfo();
    this.setWebsocket();
    this.getAverages();
    this.onStartConnection.emit();
  }

  tryReconnect() {
    const interval = setInterval(
      () => {
        if (this.connectedSuccessfully) {
          clearInterval(interval);
        }
        this.setWebsocket();
      },
      5000
    );
  }
}
