import { Component } from '@angular/core';
import { AveragesResponse } from './models/averagesResponse';
import { ResponseJSON } from './models/responseJSON';
import { ServerService } from './server.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  freeSpaces = 0;
  time = '00:00';
  progressWidth = '0%';
  currentCars = 0;
  maxCars = 0;
  grayAll = 'colored';
  grayEstimate = 'colored';
  futureFreeSpaces = 0;
  noDataMessage = false;

  readonly date = new Date();

  constructor(private serverService: ServerService) {
    this.serverService.onMessage.subscribe((response: ResponseJSON) => this.onMessage(response));
    this.serverService.onAverages.subscribe((eventData) => this.onAverages(eventData));
    this.serverService.onAverages.subscribe(values => console.log(values));
  }

  onError(): void {
    this.grayAll = 'colored';
  }

  private onMessage(response: ResponseJSON): void {
    if (response.parkingServerOk) {
      this.grayAll = '';
    }
    this.freeSpaces = response.freeSpaces;
    this.currentCars = response.freeSpaces;
    this.maxCars = response.capacity;
    this.progressWidth = (String)(((response.freeSpaces) / response.capacity) * 100) + '%';
  }

  private onAverages(response: AveragesResponse) {
    if (!response.hasData) {
      this.grayEstimate = 'colored';
      this.noDataMessage = true;
      this.futureFreeSpaces = 0;

    } else {
      this.grayEstimate = '';
      this.noDataMessage = false;
      this.futureFreeSpaces = response.chartData[1][3];
    }
  }

  checkMinutes(minutes: number): string {
    let response: string = String(minutes);
    if (minutes < 10) {
      response = '0' + response;
    }
    return response;
  }
}
