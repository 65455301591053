import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ResponseJSON } from '../models/responseJSON';
import { ServerService } from '../server.service';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.css']
})
export class ErrorMessageComponent implements OnInit {

  @Output() error = new EventEmitter<void>();

  showErrorCommunicationLost = false;
  showErrorGarageSystemNoData = false;
  connectionLostMessage = '0 seconds';
  alreadyMeasuring = false;
  secondsFromLosingConnection: number;
  lostConnectionInterval;

  constructor(private serverService: ServerService) { }

  ngOnInit() {
    this.serverService.onMessage.subscribe(
      (response: ResponseJSON) => this.onMessage(response)
    );
    this.serverService.onError.subscribe(() => this.onError());
  }


  private onMessage(response: ResponseJSON): void {
    this.showErrorCommunicationLost = false;

    if (!response.parkingServerOk) {
      this.error.emit();
      if (!this.alreadyMeasuring) {
        this.startMeasureTime();
        this.alreadyMeasuring = true;
      }
    }

    this.showErrorGarageSystemNoData = !response.parkingServerOk;
    if (!this.showErrorGarageSystemNoData) {
      this.stopMeasureTime();
    }
  }

  private onError(): void {
    if (!this.showErrorGarageSystemNoData) {
      this.showErrorCommunicationLost = true;
    }
    if (!this.alreadyMeasuring) {
      this.startMeasureTime();
      this.alreadyMeasuring = true;
    }
  }

  private startMeasureTime() {
    this.secondsFromLosingConnection = 0;
    this.lostConnectionInterval = setInterval(
      () => {
        this.secondsFromLosingConnection += 1;
        this.writeConnectionLostMessage();
      },
      1000
    );
  }

  private stopMeasureTime() {
    clearTimeout(this.lostConnectionInterval);
    this.alreadyMeasuring = false;
    this.connectionLostMessage = '0 seconds';
  }

  private writeConnectionLostMessage() {
    if (this.secondsFromLosingConnection < 60) {
      this.connectionLostMessage = this.secondsFromLosingConnection + ' seconds';
    } else {
      const minutes = Math.floor(this.secondsFromLosingConnection / 60);
      this.connectionLostMessage = minutes + ' minutes';
    }
  }
}
