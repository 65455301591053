import { Component } from '@angular/core';
import { AveragesResponse } from '../models/averagesResponse';
import { ServerService } from '../server.service';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
})
export class ChartComponent {

  barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
  };

  barChartLabels = ['0:00', '0:10', '0:20', '0:30', '0:40', '0:50', '1:00'];

  barChartType = 'line';

  barChartLegend = true;

  barChartData = [
    { data: [200, 0, 200, 0, 200, 0, 200], label: 'today' },
    { data: [0, 200, 0, 200, 0, 200, 0], label: 'average' },
  ];

  barChartColors: Array<any> = [
    { // first color
      backgroundColor: 'rgba(225,10,24,0)',
      borderColor: 'rgba(40, 0, 113, 1)',
      pointBackgroundColor: 'rgba(225,255,255,1)',
      pointBorderColor: 'rgba(0,0,0,1)',
      pointHoverBackgroundColor: 'rgba(40, 0, 113, 1)',
      pointHoverBorderColor: 'rgba(225,255,255,1)',
    },
    { // second color
      backgroundColor: 'rgba(225,10,24,0.1)',
      borderColor: 'rgba(225,10,24,0.2)',
      pointBackgroundColor: 'rgba(225,10,24,0.2)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: 'rgba(41, 94, 155, 1)',
      pointHoverBorderColor: 'rgba(225,255,255,1)',
    }];

  constructor(private serverService: ServerService) {
    this.serverService.onAverages.subscribe(
      (averageResponse: AveragesResponse) => this.setChartData(averageResponse)
    );
  }

  setChartData(averageResponse: AveragesResponse) {
    this.barChartLabels = averageResponse.chartLabels;
    this.barChartData = [
      { data: averageResponse.chartData[0], label: 'today' },
      { data: averageResponse.chartData[1], label: 'average' },
    ];
  }
}
